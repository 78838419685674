import { useState, useEffect } from "react";
import axios from "axios";
import { Lang } from "../components/Lang";
import '../assets/css/PagesContent/pages_content.css';

export const ConstructionProcess = () => {
    const [production, setProduction] = useState([]);
    const site = 'https://elitprojectgroup.am/adm/';
    const curLang = localStorage.getItem('lang');

    useEffect(() => {
        const fetchReviews = async () => {
          try {
            const response = await axios.get(`${site}api/constructionProcess.php`);
            setProduction(response.data);
          } catch (error) {
            console.error('Error fetching data: ', error);
          };
        };
    
        fetchReviews();
    }, []);

    return (
        <>
            <div className="container text-center mt-10">
                <h3 className="text-center mb-3 page_title"><Lang text='construction_process'/></h3>
                <div className="row mb-5">
                    {
                        production.map((item,index)=>{
                            return (
                                <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12 mt-5 mx-auto" key={index}>
                                    {item.date}
                                    <img src={site+'images/'+item.image} className='construction_img' alt="photo construction process" />
                                </div>
                            );
                        })
                    }
                </div>
            </div>
        </>
    );
};