import { useState, useEffect } from "react";
import axios from "axios";
import { Lang } from "../components/Lang";
import '../assets/css/PagesContent/pages_content.css';

export const Townhouses = () => {
    const [townhouses, setTownhouses] = useState([]);
    const site = 'https://elitprojectgroup.am/adm/';
    const curLang = localStorage.getItem('lang');

    useEffect(() => {
        const fetchReviews = async () => {
          try {
            const response = await axios.get(`${site}api/townhouses.php`);
            setTownhouses(response.data);
          } catch (error) {
            console.error('Error fetching data: ', error);
          };
        };
    
        fetchReviews();
    }, []);

    return (
        <>
            <div className="container text-center mt-10">
                <h3 className="text-center mb-3 page_title"><Lang text='townhouses_from_developer'/></h3>
                {
                        townhouses.map((item,index)=>{
                            return <div className="text-center" key={index}>
                                {item['text_'+curLang]}
                            </div>
                        })
                }
                <div className="row mb-5">
                {
                        townhouses.map((item,index)=>{
                            return (
                                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 mt-5 mx-auto" key={index}>
                                    <img src={site+'images/'+item.image} className='page_content_img' alt="photo townhouses" />
                                </div>
                            );
                        })
                }
                </div>
            </div>
        </>
    );
};