import { useState, useEffect } from "react";
import axios from "axios";
import { Lang } from "../components/Lang";
import '../assets/css/PagesContent/pages_content.css';

export const Production = () => {
    const [production, setProduction] = useState([]);
    const [description, setDescription] = useState(false);
    const [preview, setPreview] = useState({});
    const site = 'https://elitprojectgroup.am/adm/';
    const curLang = localStorage.getItem('lang');

    useEffect(() => {
        const fetchReviews = async () => {
          try {
            const response = await axios.get(`${site}api/buildingMaterials.php`);
            setProduction(response.data);
          } catch (error) {
            console.error('Error fetching data: ', error);
          };
        };
    
        fetchReviews();
    }, []);

    return (
        <>
            <div className="container text-center mt-10">
                <h3 className="text-center mb-3 page_title"><Lang text='production_building_materials'/></h3>
                {
                        production.map((item,index)=>{
                            return <div className="text-center" key={index}>
                                {item['text_'+curLang]}
                            </div>
                        })
                }
                <div className="row mb-5">
                    {
                        production.map((item,index)=>{
                            return (
                                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 mt-5 mx-auto" key={index}>
                                    {
                                        item.list_am ? <a href={item.list_am} target="_blank">List.am</a> : <div className="mt-4"></div>
                                    }
                                    <img src={site+'images/'+item.image} className='page_content_img' alt="photo production" onClick={()=>{
                                        if(item['desc_'+curLang]){
                                            setDescription(true);
                                            setPreview({
                                                photo: site+'images/'+item.image,
                                                text: item['desc_'+curLang]
                                            });
                                        };
                                    }} />
                                </div>
                            );
                        })
                    }
                    {
                        description ? <div id="preview_box" onClick={e=>{
                            if(e.target.getAttribute('id') === 'preview_box'){
                                setDescription(false);
                            };
                        }}>
                        <div id="preview">
                            <img src={preview.photo} alt="photo preview" />
                            <h6>{preview.text}</h6>
                        </div>
                        <div id="close" onClick={()=>setDescription(false)}>X</div>
                    </div>
                    : null
                    }
                </div>
            </div>
        </>
    );
};