import { useState, useEffect } from 'react';
import axios from 'axios';
import { Lang } from '../components/Lang';
import '../assets/css/Projects/projects.css';
import { Link } from 'react-router-dom';

export const Projects = () => {
    const [projects, setProjects] = useState([]);
    const [newProjects, setNewProjects] = useState([]);
    const site = 'https://elitprojectgroup.am/adm/';

    useEffect(() => {
        const fetchReviews = async () => {
          try {
            const response = await axios.get(`${site}api/apiProjects.php`);
            setProjects(response.data);
          } catch (error) {
            console.error('Error fetching data: ', error);
          };
          try {
            const response = await axios.get(`${site}api/apiNewProjects.php`);
            setNewProjects(response.data);
          } catch (error) {
            console.error('Error fetching data: ', error);
          };
        };
    
        fetchReviews();
    }, []);

    return (
        <>
            <h3 className="item_title mt-5 text-center" id='projects'><Lang text='projects' /></h3>
                <div className="row mt-5">
                    {
                        projects.map((item,index)=>{
                            return (
                                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 mx-auto" key={index}>
                                    <img src={site+'images/'+item.image} alt="photo" className="projects_photo img-fluid" />
                                </div>
                            );
                        })
                    }
                </div>
                <div className="row mt-5">
                    <h3 className="item_title mb-5 text-center"><Lang text='new_projects' /></h3>
                    <div className="text-end mb-5 link_gallery"><Link to='/gallery'><Lang text='video_library'/></Link></div>
                    {
                        newProjects.map((item,index)=>{
                            return (
                                <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-sm-12 col-12 mx-auto" key={index}>
                                    <img src={site+'images/'+item.image} alt="photo" className={index === 0 ? 'new_projects_img new_projects_img_first' : 'new_projects_img'} />
                                </div>
                            );
                        })
                    }
                </div>
        </>
    );
};