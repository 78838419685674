import { useState, useEffect } from "react";
import axios from "axios";
import { Lang } from "../components/Lang";

export const Apartaments = () => {
    const [apartments, setApartments] = useState([]);
    const site = 'https://elitprojectgroup.am/adm/';
    const curLang = localStorage.getItem('lang');

    useEffect(() => {
        const fetchReviews = async () => {
          try {
            const response = await axios.get(`${site}api/apartments.php`);
            setApartments(response.data);
          } catch (error) {
            console.error('Error fetching data: ', error);
          };
        };
    
        fetchReviews();
    }, []);

    return (
        <>
            <div className="container mt-10">
                <h3 className="text-center mb-3 page_title"><Lang text='appartments_from_developer'/></h3>
                {
                        apartments.map((item,index)=>{
                            return <div className="text-center" key={index}>
                                {item['text_'+curLang]}
                            </div>
                        })
                }
                <div className="row mb-5">
                {
                        apartments.map((item,index)=>{
                            return (
                                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 mt-5 mx-auto" key={index}>
                                    <img src={site+'images/'+item.image} className='img-fluid h-100' alt="photo apartaments" />
                                </div>
                            );
                        })
                }
                </div>
            </div>
        </>
    );
};