import React, { useEffect, useRef, useState } from 'react';

export const NumAnimation = ({ num, text, isPlus }) => {
    const [state, setState] = useState(0);
    const intervalRef = useRef(null);
    const [isVisible, setIsVisible] = useState(false);

    let speed = 10;

    if (num > 600) {
        speed = 1;
    } else if (num < 100) {
        speed = 15;
    }

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 3400) {
                setIsVisible(true);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    useEffect(() => {
        if (isVisible && state < num) {
            intervalRef.current = setInterval(() => {
                setState(prevState => {
                    if (prevState < num) {
                        return prevState + 1;
                    } else {
                        clearInterval(intervalRef.current);
                        return prevState;
                    }
                });
            }, speed);

            return () => clearInterval(intervalRef.current);
        } else {
            clearInterval(intervalRef.current);
        }
    }, [num, state, isVisible, speed]);

    return (
        <div>
            <div className={state ? null : 'mt-5'}>{state || null}{isPlus ? '+' : null}</div>
            <span>{text}</span>
        </div>
    );
};