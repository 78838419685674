import home_photo from '../assets/images/home_photo.jpg';
import { Lang } from '../components/Lang';
import { Services } from './Services';
import { Projects } from './Projects';
import { AboutUs } from './AboutUs';
import { ContactUs } from './ContactUs';
import '../assets/css/Home/home.css';

export const Home = () => {
    return (
        <>
            <div id='home_photo'>
                <img src={home_photo} alt="photo" />
                <div id="home_photo_title_text">
                    <div id="title"><span><Lang text='a_first'/></span> <br /> <Lang text='builder'/></div>
                    <div id="text"><Lang text='first_builder_text'/></div>
                </div>
                <div id="home_photo_read_more">
                    <span onClick={()=>{
                            document.getElementById('pages_item').scrollIntoView({behavior: 'smooth'});
                        }}><Lang text='read_more' /></span>
                </div>
            </div>
            <div className="container mt-5" id='pages_item'>
                <Services />
                <Projects />
                <AboutUs />
                <ContactUs />
            </div>
        </>
    );
};