import { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { Lang } from '../components/Lang';
import { FaDraftingCompass } from "react-icons/fa";
import { MdOutlineConstruction } from "react-icons/md";
import { BsBuildingFillGear } from "react-icons/bs";
import { LuConstruction } from "react-icons/lu";
import { RiProgress6Line } from "react-icons/ri";
import { GiFamilyHouse } from "react-icons/gi";
import '../assets/css/Services/services.css';

export const Services = () => {

    const site = 'https://elitprojectgroup.am/adm/';
    const curLang = localStorage.getItem('lang');
    const [apartments, setApartments] = useState([]);
    const [townhouses, setTownhouses] = useState([]);
    const [services, setServices] = useState([]);
    const [production, setProduction] = useState([]);
    const [process, setProcess] = useState([]);

    useEffect(() => {
        const fetchReviews = async () => {
          try {
            const response = await axios.get(`${site}api/apartments.php`);
            setApartments(response.data);
          } catch (error) {
            console.error('Error fetching data: ', error);
          };
          try {
            const response = await axios.get(`${site}api/townhouses.php`);
            setTownhouses(response.data);
          } catch (error) {
            console.error('Error fetching data: ', error);
          };
          try {
            const response = await axios.get(`${site}api/services.php`);
            setServices(response.data);
          } catch (error) {
            console.error('Error fetching data: ', error);
          };
          try {
            const response = await axios.get(`${site}api/buildingMaterials.php`);
            setProduction(response.data);
          } catch (error) {
            console.error('Error fetching data: ', error);
          };
          try {
            const response = await axios.get(`${site}api/constructionProcess.php`);
            setProcess(response.data);
          } catch (error) {
            console.error('Error fetching data: ', error);
          };
        };
    
        fetchReviews();
    }, []);

    return (
        <>
            <h3 className="mt-5 item_title text-center" id='services'><Lang text='services' /></h3>
                <div id='services_title'>
                    <Lang text='the_best'/> <span><Lang text='builder'/></span>
                    <br />
                    <span><Lang text='the_best'/></span> <Lang text='quality'/>
                </div>
                <div className="services_box_items row mt-1">
                    <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
                        <Link to='/apartaments'>
                        <div className="services_item mt-4">
                            <FaDraftingCompass />
                            <div className="services_item_title">
                            <Lang text='appartments_from_developer'/>
                            <br /><br />
                            </div>
                            <h6>
                            {
                                apartments.map(item=>item['text_'+curLang] ? item['text_'+curLang].substring(0,37)+'...' : null)
                            }
                            </h6>
                        </div>
                        </Link>
                    </div>
                    <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
                        <Link to='/townhouses'>
                        <div className="services_item mt-4">
                            <MdOutlineConstruction />
                            <div className="services_item_title">
                            <Lang text='townhouses_from_developer'/>
                            <br /><br />
                            </div>
                            <h6>
                            {
                                townhouses.map(item=>item['text_'+curLang] ? item['text_'+curLang].substring(0,37)+'...' : null)
                            }
                            </h6>
                        </div>
                        </Link>
                    </div>
                    <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
                        <Link to='/services'>
                        <div className="services_item mt-4">
                            <BsBuildingFillGear />
                            <div className="services_item_title">
                            <Lang text='services'/>
                            <br /><br />
                            </div>
                            <h6>
                            {
                                services.map(item=>item['text_'+curLang] ? item['text_'+curLang].substring(0,37)+'...' : null)
                            }
                            </h6>
                        </div>
                        </Link>
                    </div>
                    <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
                        <Link to='/production'>
                        <div className="services_item mt-4">
                        <LuConstruction />
                            <div className="services_item_title">
                            <Lang text='production_building_materials'/>
                            </div>
                            <h6>
                            {
                                production.map(item=>item['text_'+curLang] ? item['text_'+curLang].substring(0,37)+'...' : null)
                            }
                            </h6>
                        </div>
                        </Link>
                    </div>
                    <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
                        <Link to='/construction-process'>
                        <div className="services_item mt-4">
                        <RiProgress6Line />
                            <div className="services_item_title">
                            <Lang text='construction_process'/>
                            </div>
                            <h6>
                            {
                                process[process.length-1]?.date
                            }
                            </h6>
                        </div>
                        </Link>
                    </div>
                    <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
                    <Link to='/finished-works'>
                        <div className="services_item mt-4">
                        <GiFamilyHouse />
                            <div className="services_item_title">
                            <Lang text='finished_works'/>
                            </div>
                            <h6><Lang text='view_more'/></h6>
                        </div>
                        </Link>
                    </div>
                </div>
        </>
    );
};