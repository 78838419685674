import emailjs from 'emailjs-com';
import { Lang } from '../components/Lang';
import '../assets/css/ContactUs/contact_us.css';

export const ContactUs = () => {

    const handleSubmit = (e) => {
        e.preventDefault();

        const email = document.getElementById('email').value;
    
        const templateParams = {
          from_name: email,
          to_name: email,
          subject: `Նոր հաղորդագրություն ${email} ից`,
          message: {
            name: document.getElementById('name').value,
            email: email,
            phone: document.getElementById('phone').value,
            message: document.getElementById('message').value
          },
        };
    
        emailjs.send('service_u45663r', 'template_4sbip1v', templateParams, 'gpqUPLO47KyRtR_b-')
          .then((response) => {
            console.log(response.status, response.text);
            window.location.reload();
          }, (error) => {
            console.log(error.text);
            alert('есть проблема, проверьте всю информацию');
          });
      };

    return (
        <>
            <h3 className="item_title mt-5 text-center" id='contact_us'><Lang text='contact_us' /></h3>
            <div className="row mt-5">
                <div className="col-xxl-4 col-xl-4 col-lg-12 col-md-12 col-sm-12 col-12 contact_us_inputes">
                    <form method="post" id='contact_us_form' onSubmit={handleSubmit}>
                        <input type="text" placeholder={Lang({text: 'your_name'})} required id='name' />
                        <input type="email" placeholder={Lang({text: 'your_email'})} required id='email' />
                        <input type="number" placeholder={Lang({text: 'phone'})} id='phone' />
                        <textarea placeholder={Lang({text: 'message'})} required id="message"></textarea>

                        <div id='contact_us_button'>
                            <button type="submit"><Lang text='submit'/></button>
                        </div>
                    </form>
                </div>
                <div className="col-xxl-7 col-xl-7 col-lg-12 col-md-12 col-sm-12 col-12 offset-xxl-1 offset-xl-1 offset-lg-0 offset-md-0 offset-sm-0 offset-0 contact_us_map_info">
                <div id="google_map">
                <iframe src="https://yandex.com/map-widget/v1/?ll=44.628076%2C40.271366&mode=search&ol=geo&ouri=ymapsbm1%3A%2F%2Fgeo%3Fdata%3DCgo1MzMyNzk4OTY2EnDVgNWh1bXVodW91b_VodW2LCDUv9W41b_VodW11oTVqyDVtNWh1oDVpiwg1LHVotW41b7VtdWh1bYsINWi1bbVodWv1aXVrNWrINWw1aHVtNWh1azVq9aAINSx1aLVuNW-1bXVodW2INWK1aHWgNWvIgoNFIMyQhXhFSFC&z=19.77"></iframe>
                </div>
                    <div className="contact_us_infos">
                        <div className="contac_us_info">
                            <h3><span>|</span><Lang text='address'/></h3>
                            <h6>
                                <Lang text='address_text'/>
                                <br />
                                <Lang text='address_text_second'/>
                            </h6>
                        </div>
                        <div className="contac_us_info">
                            <h3><span>|</span><Lang text='contacts'/></h3>
                            <h6>
                                <a href="mailto:info.elit@mail.ru">info.elit@mail.ru</a>
                                <br />
                                <a href="tel:+37493363882">+374 93 36 38 82</a>
                                <br />
                                <a href="tel:+37441248818">+374 41 24 88 18</a>
                                <br />
                                <a href="viber://chat?number=37493363882/">Viber</a>
                                <br />
                                <a href="whatsapp://send?phone=+37493363882/">Whatsapp</a>
                                <br />
                                <a href="https://t.me/+37493363882" target='_blank'>Telegram</a>
                            </h6>
                        </div>
                        <div className="contac_us_info">
                            <h3><span>|</span><Lang text='social_media'/></h3>
                            <h6>
                                <a href="https://www.facebook.com/elitprojectgroup?mibextid=ZbWKwL" target='_blank'>Facebook</a>
                                <br />
                                <a href="https://www.facebook.com/profile.php?id=100076647567563" target='_blank'>Facebook 2</a>
                                <br />
                                <a href="https://www.facebook.com/abovyanpark" target='_blank'>Facebook 3</a>
                                <br />
                                <a href="https://www.instagram.com/abovyanpark/" target='_blank'>Instagram</a>
                                <br />
                                <a href="https://www.list.am/user/146709" target='_blank'>List.am</a>
                                <br />
                                <a href="https://www.list.am/user/3019234" target='_blank'>List.am 2</a>
                            </h6>
                        </div>
                        <div className="contac_us_info">
                            <h3><span>|</span><Lang text='sales_department'/></h3>
                            <h6>
                                <a href="tel:+37493363882">+374 93 36 38 82</a>
                                <br />
                                <a href="tel:+37499228818">+374 99 22 88 18</a>
                            </h6>
                        </div>
                        <div className="contac_us_info">
                            <h3><span>|</span><Lang text='work_with_us'/></h3>
                            <h6><span>" <Lang text='elit_project_group'/> "</span> <Lang text='work_with_us_text'/> <span><a href="mailto:info.elit@mail.ru">info.elit@mail.ru</a></span> <Lang text='to_email'/></h6>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
