import { useState, useEffect } from 'react';
import axios from 'axios';
import '../assets/css/Gallery/gallery.css';

export const Gallery = () => {
    const [gallery, setGallery] = useState([]);
    const site = 'https://elitprojectgroup.am/adm/';
    const [photoBig, setPhotoBig] = useState(false);
    const [initialPhoto, setInitialPhoto] = useState(null);
    
    useEffect(() => {
        const fetchReviews = async () => {
          try {
            const response = await axios.get(`${site}api/videoPhoto.php`);
            setGallery(response.data);
          } catch (error) {
            console.error('Error fetching data: ', error);
          };
        };
    
        fetchReviews();
    }, []);

    return (
        <>
            <div className="container-fluid mt-10">
                <div className="row">
                        {
                            gallery.map((photo, key)=>{
                                return (
                                    <div className="col-xxl-3 col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12 mb-5" onClick={()=>{
                                        setInitialPhoto(key);
                                        setPhotoBig(true);
                                    }} key={key}>
                                        <img src={site+'images/'+photo.image} className="gallery_photo img-fluid" alt="gallery photo" />
                                    </div>
                                )
                            })
                        }
                </div>
            </div>

            {
                photoBig ? <div className="photo_open" onClick={(e)=>{
                    if(e.target.getAttribute('class') === 'photo_open'){
                        setPhotoBig(false);
                    }
                }}>
                <div className="close_photo" onClick={()=>{
                    setPhotoBig(false);
                }}>X</div>
                <div className="photo_prev" onClick={()=>{
          if(initialPhoto !== 0){
              setInitialPhoto(initialPhoto-1);
          }else{
            setInitialPhoto(gallery.length-1);
          }
      }}>&lt;</div>
      <img src={site+'images/'+gallery[initialPhoto].image} alt="gallery photo" />
      <div className="photo_next" onClick={()=>{
          if(initialPhoto !== gallery.length-1){
              setInitialPhoto(initialPhoto+1);
          }else{
            setInitialPhoto(0);
          }
      }}>&gt;</div>
  </div> : null
  }
        </>
    );
};