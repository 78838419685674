import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Link } from 'react-router-dom';
import { Lang } from './Lang';
import logo from '../assets/images/logo.png';
import en from '../assets/images/en.png';
import ru from '../assets/images/ru.png';
import am from '../assets/images/am.png';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../assets/css/Header/header.css';

export const Header = ({ site, setSite }) => {
    const curLang = localStorage.getItem('lang');
    const media = window.matchMedia("(max-width: 1199px)");

    return (
        <>
            <Navbar expand='xl' id="header">
      <div className='container-fluid'>
        <Navbar.Brand><Link to="/" onClick={()=>window.scrollTo(0,0)}><img src={logo} alt="logo" className='logo' /></Link></Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" id='nav_toogler' />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className='me-auto mx-auto nav_links'>
            <Link to="/" onClick={()=>{
              window.scrollTo(0,0);
              if(media.matches){
            document.getElementById('nav_toogler').click();
        }
            }}><Lang text='home' /></Link>
            <Link to="/" onClick={()=>{
              setTimeout(()=>{
                document.getElementById('services').scrollIntoView({behavior: 'smooth'});
              }, 1);
              if(media.matches){
            document.getElementById('nav_toogler').click();
        }
            }}><Lang text='services' /></Link>
            <Link to="/" onClick={()=>{
              setTimeout(()=>{
                document.getElementById('projects').scrollIntoView({behavior: 'smooth'});
              }, 1);
              if(media.matches){
            document.getElementById('nav_toogler').click();
        }
            }}><Lang text='projects' /></Link>
            <Link to="/" onClick={()=>{
              setTimeout(()=>{
                document.getElementById('contact_us').scrollIntoView({behavior: 'smooth'});
              }, 1);
              if(media.matches){
            document.getElementById('nav_toogler').click();
        }
            }}><Lang text='work' /></Link>
            <Link to="/" onClick={()=>{
              setTimeout(()=>{
                document.getElementById('about_us').scrollIntoView({behavior: 'smooth'});
              }, 1);
              if(media.matches){
            document.getElementById('nav_toogler').click();
        }
            }}><Lang text='about_us' /></Link>
            <Link to="/" onClick={()=>{
              setTimeout(()=>{
                document.getElementById('contact_us').scrollIntoView({behavior: 'smooth'});
              }, 1);
              if(media.matches){
            document.getElementById('nav_toogler').click();
        }
            }}><Lang text='contact_us' /></Link>
          </Nav>
          <NavDropdown title={curLang === 'en' ? <><img src={en} alt="en" /> English</> : 
          curLang === 'ru' ? <><img src={ru} alt="ru" /> Русский</> : 
          <><img src={am} alt="am" /> Հայերեն</>} id="basic-nav-dropdown">
              <NavDropdown.Item value="en" onClick={()=>{
                localStorage.setItem('lang', 'en');
                setSite(!site);
              }}><img src={en} alt="en" /> English</NavDropdown.Item>
              <NavDropdown.Item value="ru" onClick={()=>{
                localStorage.setItem('lang', 'ru');
                setSite(!site);
              }}><img src={ru} alt="ru" /> Русский</NavDropdown.Item>
              <NavDropdown.Item value="am" onClick={()=>{
                localStorage.setItem('lang', 'am');
                setSite(!site);
              }}><img src={am} alt="am" /> Հայերեն</NavDropdown.Item>
            </NavDropdown>
        </Navbar.Collapse>
      </div>
    </Navbar>
    </>
    )
};