import { useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Header } from './components/Header';
import { Scroll } from './components/Scroll';
import { Home } from "./pages/Home";
import { Apartaments } from "./pages/Apartaments";
import { Townhouses } from "./pages/Townhouses";
import { ServicesPage } from "./pages/ServicesPage";
import { Production } from "./pages/Production";
import { ConstructionProcess } from "./pages/ConstructionProcess";
import { FinishedWorks } from "./pages/FinishedWorks";
import { Gallery } from "./pages/Gallery";
import { Error } from "./pages/Error";
import { Footer } from "./components/Footer";
import './assets/css/main.css';

export default function App() {

  if(!localStorage.getItem('lang')){
    localStorage.setItem('lang', 'am');
  };
  const [site, setSite] = useState(false);

  return (
    <BrowserRouter>
      <Header site={site} setSite={setSite} />
      <Scroll />
      <div className="content">
        <Routes>
          <Route index element={<Home />} />
          <Route path="/apartaments" element={<Apartaments />} />
          <Route path="/townhouses" element={<Townhouses />} />
          <Route path="/services" element={<ServicesPage />} />
          <Route path="/production" element={<Production />} />
          <Route path="/construction-process" element={<ConstructionProcess />} />
          <Route path="/finished-works" element={<FinishedWorks />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="*" element={<Error />} />
        </Routes>
      </div>
      <Footer />
    </BrowserRouter>
  );
};