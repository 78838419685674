import { Lang } from '../components/Lang';
import home1 from '../assets/images/home1.webp';
import { NumAnimation } from '../components/NumAnimation';
import '../assets/css/About/about.css';

export const AboutUs = () => {
    return (
        <>
            <h3 className="item_title mt-5 text-center" id='about_us'><Lang text='about_us' /></h3>
                <div className="row mt-5">
                    <div className="col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12">
                        <img src={home1} alt="photo" className="home_photo1 img-fluid" />
                    </div>
                    <div className="col-xxl-5 col-xl-5 col-lg-12 col-md-12 col-sm-12 col-12 offset-xxl-1 offset-xl-1 offset-lg-0 offset-md-0 offset-sm-0 offset-0 mt-4 about_us_title_text">
                        <h6 className='mt-3'>
                            <Lang text='about_text1' />
                            <br /><br />
                            <Lang text='about_text2' />
                            <br /><br />
                            <Lang text='about_text3' />
                            <br /><br />
                            <Lang text='about_text4' />
                        </h6>
                        <div className="num_animations">
                            <NumAnimation num={20} text={Lang({text: 'work_experience'})} />
                            <NumAnimation num={50} text={Lang({text: 'workplace'})} isPlus={true} />
                            <NumAnimation text={Lang({text: 'licenses_certificates'})} />
                        </div>
                    </div>
                </div>
                <div className="banks mt-5">
                    <Lang text='partner_banks'/>
                    <br />
                    <a href="https://estate.ameriabank.am/building/124" target='_blank'><Lang text='ameriabank'/></a>
                    <br />
                    <a href='https://www.aeb.am/' target='_blank'><Lang text='hay_econom_bank'/></a>
                </div>
        </>
    );
};